<template>
    <div v-if="allParticipants.length">
        <select class="custom-select" v-model="selectedParticipant">
            <option value="">{{ $t('Team') }}</option>
            <option v-for="item in allParticipants"
                    :key="item.id"
                    :value="item.id">{{item.name}}
            </option>
        </select>
    </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  export default {
    name: "TeamFilter",
    props: {
      team: {
        default: null
      }
    },
    data(){
      return {
        selectedParticipant: this.team ?? ""
      }
    },
    computed: {
      ...mapState({
        allParticipants: state => state.participant.participantsList,
      }),
    },
    watch:{
      selectedParticipant(newTeam) {
        this.$emit('update:team', newTeam)
      }
    },
    methods:{
      ...mapActions(['GET_PARTICIPANTS_LIST']),
    },
    created() {
      this.GET_PARTICIPANTS_LIST();
    },
  }
</script>

<style scoped>

</style>